import PurchaseHistory from "../../components/purchase/PurchaseHistory"



function PurchaseHistoryPage(){
    return (
        <>
        <PurchaseHistory/>
        </>
    )
}
export default PurchaseHistoryPage