import Outstanding from "../../components/outstanding/Outstanding";

function OutstandingPage() {
  return (
    <>
      <Outstanding />
    </>
  );
}
export default OutstandingPage;
