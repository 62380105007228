import Inventory from "../../components/inventory/Inventory";

function InventoryPage() {
  return (
    <>
      <Inventory />
    </>
  );
}
export default InventoryPage;
