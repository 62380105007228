import Invoices from "../../components/invoices/Invoices";

function InvoicesPage() {
  return (
    <>
      <Invoices />
    </>
  );
}
export default InvoicesPage;
