import DeliveryChallan from "../../components/deliveryChallan/DeliveryChallan";

function DeliveryChallanPage() {
  return (
    <>
      <DeliveryChallan />
    </>
  );
}
export default DeliveryChallanPage;
