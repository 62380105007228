import React from 'react'
import PurchaseOrderList from '../../components/purchaseOrderList/PurchaseOrderList'

function PurchaseOrderListPage() {
    return (
        <div style={{ width: 100 + "vw" }}>
            <PurchaseOrderList />
        </div>
    )
}

export default PurchaseOrderListPage
