import Quotation from "../../components/quotations/Quotation";

function QuotationsPage() {
  return (
    <>
      <Quotation />
    </>
  );
}
export default QuotationsPage;
