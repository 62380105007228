import React, { useEffect } from 'react'
import TrackOrder from '../../components/track-order/TrackOrder'
// import Breadcrumb from '../../shared/breadcrumb/Breadcrumb'

function TrackOrderPage({setShow}) {

  return (
    <>
        {/* <Breadcrumb title="Track Order" /> */}
        <TrackOrder />
    </>
  )
}

export default TrackOrderPage