import WishList from "../../components/wishlist/WishList";

function WishlistPage() {
  return (
    <>
      <WishList />
    </>
  );
}
export default WishlistPage;
