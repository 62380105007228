import TopHeader from "./top-header/TopHeader";

import "./Header.css";

function Header() {
  return (
    <>
      <TopHeader />
    </>
  );
}

export default Header;
