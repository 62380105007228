import CustomerSupportTicket from "../../components/customerSupportTicket/CustomerSupportTicket";

function CustomerSupportTicketPage() {
  return (
    <>
      <CustomerSupportTicket />
    </>
  );
}
export default CustomerSupportTicketPage;
