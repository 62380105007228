import CustomerDashbord from "../../components/customerDashbord/CustomerDashbord";

function CustomerDashbordPage() {
  return (
    <>
      <CustomerDashbord/>
      
    </>
  )
}
export default CustomerDashbordPage;